import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import logo from '../../assets/predictor_logo.png';
import { useSelector } from 'react-redux';
import {
  getDropDownStreams,
  getStreamCourseLevels
} from "../../actions/Listings";
import doctor_group from '../../assets/predictor_doctor.png';
import './Predictor.css';
import server from '../../utils/Server';
import whitelogo from '../../assets/white_logo.png';

const Predictor = () => {
  // Individual states for each form field
  const [rank, setRank] = useState('');
  const [states, setStates] = useState([]);
  const [interestedState, setInterestedState] = useState(0);
  const [speciality, setSpeciality] = useState('');
  const [category, setCategory] = useState('');
  const [budget, setBudget] = useState('');
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authorities, setAuthorities] = useState([]);

  const { is_auth, auth_user, streams, levels } = useSelector((state) => ({
    is_auth: state.home.is_auth,
    auth_user: state.home.auth_user,
    streams: state.list.streams,
    levels: state.list.course_levels
  }));

  // Error state for validation
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Fetch initial data
    server.get("/getallcourses")
      .then((resp) => {
        setCourses(resp.data.courses);
      });

    server.get('/get_cutoff_states')
      .then((resp) => {
        setStates(resp.data.states);
      });

    server.get('/get_all_cutoff_authorities')
      .then((resp) => {
        setAuthorities(resp.data.cutoff_authorities);
      });

    // Set initial values from localStorage
    const storedRank = localStorage.getItem('rank') || '';
    const storedAuthId = localStorage.getItem('auth_id');
    const storedCategory = localStorage.getItem('cut_off_category_id') || 0;
    const storedSpeciality = localStorage.getItem('course_id') || 0;
    const storedBudget = localStorage.getItem('fees') || '';

    setRank(storedRank);
    if (storedAuthId !== null && storedAuthId !== '') {
      setInterestedState(storedAuthId);
    }
    setCategory(storedCategory);
    setSpeciality(storedSpeciality);
    setBudget(storedBudget);

  }, []);

  // Fetch categories when interestedState changes
  useEffect(() => {
    var formdata=new FormData();
    formdata.append('page',2);
    server.post('/counter',formdata)
    .then((response) => {
     console.log(response.data.message);
    })
    if (interestedState) {
      setCategoriesArray(interestedState);
    }
  }, [interestedState]);

  const validate = () => {
    const newErrors = {};
    if (!rank) newErrors.rank = 'Rank is required';
    if (!interestedState) newErrors.interestedState = 'Authority is required';
    if (!speciality) newErrors.speciality = 'Speciality is required';
    if (!category) newErrors.category = 'Category is required';
    if (!budget || budget <= 0) newErrors.budget = 'Valid budget is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const setLocalValues = () => {
    localStorage.setItem('rank', rank);
    localStorage.setItem('auth_id', interestedState);
    localStorage.setItem('course_id', speciality);
    localStorage.setItem('cut_off_category_id', category);
    localStorage.setItem('fees', budget);
    localStorage.setItem('order',1)
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (validate()) {
      server.get(`/get_user_details/${auth_user.id}`)
    .then((response)=>{
      // if(response.data.auth_user.cutoff_counter >1 && (response.data.auth_user.paid_status === null || response.data.auth_user.paid_status === 0)){
      //   window.location.hash="/predictor_form_payment"
      // }
      let auth_id=localStorage.getItem('auth_id')
      const paidAuthorities = response.data.auth_user.paid_authorities || ""; // Ensure it's not null/undefined
      const isAuthIdIncluded = paidAuthorities.split(',').includes(String(auth_id));
      console.log('inside  form')
      if (response.data.auth_user.cutoff_counter >1 && (response.data.auth_user.paid_status !== 1 || response.data.auth_user.paid_status === null && isAuthIdIncluded ===false)) {
        console.log('outside  form')
        window.location.hash = "/predictor_form_payment";
      }
    })
      // Form submission logic
      if (setLocalValues() === true) {
        window.location.hash = "/predictor_colleges";
      }
    }
  };

  const auth_token = localStorage.getItem('auth_token');
  localStorage.setItem('route', '/predictor');
  if (auth_token === '' || auth_token === null) {
    return <Redirect to="/login" />;
  }

  var refresh_count = localStorage.getItem("otp_refresh");
  let count = parseInt(refresh_count);
  if (count === 0) {
    count = count + 1;
    localStorage.setItem("otp_refresh", 1);
    window.location.reload();
  }

  const setCategoriesArray = (value) => {
    setCategory('');
    setInterestedState(value);

    server.get(`/get_all_categories/${value}`)
      .then((resp) => {
        setCategories(resp.data.categories);
      });
  };

  return (
    <div className="container-fluid predictor_page predictor_bg_blue_color" style={{ overflowX: "auto", overflowY: "hidden" }}>
      <div className="row mt-2">
        <div className="col-md-6 float-right">
          {/* <img src={logo} alt="Logo" className="ml-4 img-fluid logo_predictor my-3 pt-5" /> */}
          <img src={whitelogo} alt="Logo" className="ml-4 img-fluid logo_predictor my-3 pt-5" />
          <img src={doctor_group} width="100%" className="img-fluid predictor_doctor_image_position" />
        </div>
        <div className="col-md-6 text-left pt-5 float-right predictor_second_div">
          <div className="card predictor-card mt-5">
            <div className="card-body">
              <h2 className="text-center predictor_heading">NEET-PG College Predictor</h2>
              <form onSubmit={handleSubmit}>
                {/* Rank and State */}
                <div className="form-row mb-3">
                  <div className="col-md-6">
                    <label className="predictor_lable" htmlFor="rank">NEET-PG Rank</label>
                    <input
                      id="rank"
                      type="text"
                      className={`form-control ${errors.rank ? 'is-invalid' : ''}`}
                      placeholder="Rank"
                      value={rank}
                      onChange={(e) => setRank(e.target.value)}
                    />
                    {errors.rank && <div className="invalid-feedback">{errors.rank}</div>}
                    <input id="user_id" name="user_id" type="hidden" value={auth_user.id} />
                  </div>
                  <div className="col-sm-6 mt-3 mt-sm-0">
                    <label className="predictor_lable" htmlFor="state">Interested States/Authority</label>
                    <select
                      id="state"
                      className={`form-control ${errors.interestedState ? 'is-invalid' : ''}`}
                      value={interestedState}
                      onChange={(e) => setCategoriesArray(e.target.value)}
                    >
                      <option value="0">Interested States/Authority</option>
                      {authorities && authorities.length > 0 && authorities.map((authority, index) => (
  <option key={index} value={authority.id}>{authority.author_name}</option>
))}
                    </select>
                    {errors.interestedState && <div className="invalid-feedback">{errors.interestedState}</div>}
                  </div>
                </div>

                {/* Speciality */}
                <div className="form-group mb-3">
                  <label className="predictor_lable" htmlFor="speciality">Speciality</label>
                  <select
                    id="speciality"
                    className={`form-control ${errors.speciality ? 'is-invalid' : ''}`}
                    value={speciality}
                    onChange={(e) => setSpeciality(e.target.value)}
                  >
                    <option value="">Speciality</option>
                    {courses.map((course) => (
                      <option key={course.id} value={course.id}>{course.course_name}</option>
                    ))}
                  </select>
                  {errors.speciality && <div className="invalid-feedback">{errors.speciality}</div>}
                </div>

                {/* Category and Budget */}
                <div className="form-row mb-3">
                  <div className="col-12 col-sm-6">
                    <label className="predictor_lable" htmlFor="category">Category (Check the information brochure)</label>
                    <select
                      id="category"
                      className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="">Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>{category.category}</option>
                      ))}
                    </select>
                    {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <label className="predictor_lable" htmlFor="budget">Enter Maximum Budget per year (INR)</label>
                    <input
                      id="budget"
                      type="number"
                      className={`form-control ${errors.budget ? 'is-invalid' : ''}`}
                      placeholder="Maximum Budget per year (INR)"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    />
                    {errors.budget && <div className="invalid-feedback">{errors.budget}</div>}
                  </div>
                </div>

                <div className="pt-4">
                  <button
                    type="submit"
                    className="btn-block m-auto py-2 predictor_submit_button rounded"
                    style={{ backgroundColor: '#ff6a00', color: '#fff', border: "0" }}
                  >
                    <strong>Predict My College</strong>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Predictor;
