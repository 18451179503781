import React from "react";
import "./Routes.css";
import { NavLink, Link, HashRouter as Router, Route } from "react-router-dom";
import Home from "../Home";
import AboutUs from "../AboutUs";
import Pricing from "../Pricing";
import Checkout from "../Checkout";
import Predictor from "../Predictor";
import PaymentSuccess from "../Checkout/PaymentSuccess";
import Register from "../Register";
import Contact from "../Contact";
import StateFilters from "../StateFilters";
import CollegeFilters from "../CollegeFilters";
import CollegeDetails from "../CollegeDetails";
import NewsUpdates from "../NewsUpdates";
import NewsDetails from "../NewsUpdates/NewsDetails";
import EventCalendar from "../EventCalendar";
import Events from "../EventCalendar/Events";
import usaimg from '../../assets/usa.png';
import australia  from '../../assets/australia.png';
import uk from '../../assets/uk.png';
import Cutoffs from "../Cutoffs";
import TermsConditions from "../TermsConditions";
import PrivacyPolicy from "../PrivacyPolicy";
import CollegeCompare from "../CollegeCompare";
import ChangeStreamSettings from "../EditProfile/ChangeStreamSettings";
import CutoffSettings from "../EditProfile/CutoffSettings";
import EditProfile from "../EditProfile";
import ChangePassword from "../EditProfile/ChangePassword";
import ForgotPassword from "../EditProfile/ForgotPassword";
import Videos from "../Videos";
import Result from "../Result";

import MBBSCourse from "../Courses/MBBSCourse";
import MDMSCourse from "../Courses/MDMSCourse";
import PGDiplomaCourse from "../Courses/PGDiplomeCourse";
import DNBCourse from "../Courses/DNBCourse";
import BDSCourse from "../Courses/BDSCourse";
import MDSCourse from "../Courses/MDSCourse";
import BAMSCourse from "../Courses/BAMSCourse";
import BHMSCourse from "../Courses/BHMSCourse";
import BUMSCourse from "../Courses/BUMSCourse";
import BVScCourse from "../Courses/BVScCourse";
import MVScCourse from "../Courses/MVScCourse";
import RankPredictor from "../RankPredictor";
import UKIcon from "../../assets/uk.png";
import USAIcon from "../../assets/usa.png";
import AUSIcon from "../../assets/australia.png";
import PredictedColleges from '../Predictedcolleges';
import { connect } from "react-redux";
import Logo from "../../assets/logo.png";
import Login from '../Login';
import Otp from "../Otp";
import PredictorformPayment from '../PredictorformPayment';
import DownloadPayment from "../DownloadPayment/DownloadPayment";
import RegisterOTP from "../RegisterOTP/RegisterOTP";
import PredictorBenefits from '../PredictorBenefits';
class Routes extends React.Component {
    constructor(props) {
        super(props);
        this.state = { show_courses: 0, show_states: 0 };
        this.handleStateNavigate = this.handleStateNavigate.bind(this);
        
        
    }
    
    handleStateNavigate = state_id => {
        window.location.href = "/#/college_filters?c_state=" + state_id;
    };

    render() {
        const { show_courses, show_states } = this.state;
        const { is_auth, auth_user, college_states } = this.props;
       const  mobile_verified=JSON.parse(localStorage.getItem('auth_user'))
       
        return (
            <div className="row">
                <div className="col mobile-col" 
                // style={{backgroundColor:"#233974"}}
                // style={{backgroundColor:"#fde6da"}}
                >
                    <Router>
                        <nav
                            className="navbar navbar-expand-md"
                            id="home_navbar">
                            <button
                                className="navbar_btn navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#main_header_navbar"
                                aria-controls="main_header_navbar"
                                aria-expanded="false"
                                aria-label="{{ __('Toggle navigation') }}">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/menu_button.png"
                                    }
                                    alt="Menu"
                                    height="30"
                                />
                            </button>

                            <Link to="/" className="navbar-brand">
                                <img src={Logo} alt="Logo" height="40"  style={{borderRadius:"0"}}/>
                            </Link>

                            <div
                                className="collapse navbar-collapse"
                                id="main_header_navbar">
                                <ul className="navbar-nav ml-auto sticky">
                                    <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/">
                                            Home
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/news_updates">
                                            News
                                        </NavLink>
                                    </li> */}
                                    {/* <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/calendar">
                                            Calendar
                                        </NavLink>
                                    </li> */}
                                    {/* {is_auth && auth_user  && parseInt(mobile_verified.mobile_verified)===1? (                                        */}
                                        <li>
                                            <NavLink className="nav-link"
                                             activeClassName="link_active"
                                                   exact
                                                   to="/predictor">
                                                    Predictor
                                               </NavLink>     

                                        </li>
                                        <li>
                                            <NavLink className="nav-link"
                                             activeClassName="link_active"
                                                   exact
                                                   to="/rank_predictor">
                                                    Rank Predictor
                                               </NavLink>     

                                        </li>
                                    {/* ):(<li></li>)} */}
                                        
                                    {/* Show courses if courses */}
                                    {/* <li>
                                        <div
                                            className="states_dropdown"
                                            onMouseEnter={() =>
                                                this.setState({
                                                    show_courses: 1
                                                })
                                            }
                                            onMouseLeave={() =>
                                                this.setState({
                                                    show_courses: 0
                                                })
                                            }>
                                            <span className="nav-link">
                                                Courses
                                                <i className="fa fa-caret-down"></i>
                                            </span>
                                            {show_courses ? (
                                                <div className="states_dropdown_content animated fadeIn">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                                            <h6 className="text-orange">
                                                                Medical
                                                            </h6>
                                                            <hr className="hori_line ml-0" />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/mbbs_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                MBBS
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/md_ms_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                MD | MS
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/pg_diploma_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                PG Diploma
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/dnb_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                DNB
                                                            </NavLink>
                                                            <br />
                                                        </div>
                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                                            <h6 className="text-orange">
                                                                Dental
                                                            </h6>
                                                            <hr className="hori_line ml-0" />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/bds_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                BDS
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/mds_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                MDS
                                                            </NavLink>
                                                            <br />
                                                        </div>
                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                                            <h6 className="text-orange">
                                                                Ayush
                                                            </h6>
                                                            <hr className="hori_line ml-0" />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/bams_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                BAMS
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/bhms_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                BHMS
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/bums_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                BUMS
                                                            </NavLink>
                                                            <br />
                                                        </div>
                                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                                            <h6 className="text-orange">
                                                                Veterinary
                                                            </h6>
                                                            <hr className="hori_line ml-0" />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/bvsc_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                BVSc
                                                            </NavLink>
                                                            <br />
                                                            <NavLink
                                                                className="state_link"
                                                                activeClassName="link_active"
                                                                exact
                                                                to="/mvsc_course">
                                                                <i className="fa fa-graduation-cap mr-1"></i>
                                                                MVSc
                                                            </NavLink>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </li> */}

                                    {/* States dropdown if states exists */}
                                    <li>
                                        <div
                                            className="states_dropdown"
                                            onMouseEnter={() =>
                                                this.setState({
                                                    show_states: 1
                                                })
                                            }
                                            onMouseLeave={() =>
                                                this.setState({
                                                    show_states: 0
                                                })
                                            }>
                                            <NavLink
                                                className="nav-link"
                                                activeClassName="link_active"
                                                exact
                                                to="/college_filters">
                                                Colleges
                                                <i className="fa fa-caret-down"></i>
                                            </NavLink>
                                            {show_states ? (
                                                <div className="states_dropdown_content animated fadeIn">
                                                    <div className="row">
                                                        {college_states &&
                                                        college_states.length >
                                                            0
                                                            ? college_states.map(
                                                                  state => (
                                                                      <div
                                                                          key={
                                                                              state.id
                                                                          }
                                                                          className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12">
                                                                          <span
                                                                              className="state_link"
                                                                              onClick={() =>
                                                                                  this.handleStateNavigate(
                                                                                      state.id
                                                                                  )
                                                                              }>
                                                                              <i className="fa fa-globe mr-1"></i>
                                                                              {
                                                                                  state.state_name
                                                                              }
                                                                          </span>
                                                                      </div>
                                                                  )
                                                              )
                                                            : null}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </li>

                                    <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/videos">
                                            Videos
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/college_compare">
                                            Compare
                                        </NavLink>
                                    </li> */}
                                    {/* <li>
                                        <NavLink
                                            className="nav-link"
                                            activeClassName="link_active"
                                            exact
                                            to="/pricing">
                                            Pricing
                                        </NavLink>
                                    </li> */}
                                    {/* <li> */}
                                            {/* <NavLink className="nav-link"
                                             activeClassName="link_active"
                                                   exact
                                                   to="" href="https://www.goocampusuk.com/">

                                                    <img src={uk} alt="australia" height="20px"  />UK
                                               </NavLink>      */}

                                            {/* <a className="nav-link" target="_blank" href="https://www.goocampusuk.com/"><img src={uk} alt="australia" height="20px"  />UK</a> */}

                                        {/* </li> */}
                                    {/* <li> */}
                                            {/* <NavLink className="nav-link"
                                             activeClassName="link_active"
                                                   exact
                                                   to="https://www.goocampususa.com/">
                                                    <img src={usaimg} alt="USA" height="20px"  />USA
                                               </NavLink>      */}
                                               {/* <a className="nav-link" target="_blank" href="https://www.goocampususa.com/"><img src={usaimg} alt="USA" height="20px"  />USA</a> */}

                                        {/* </li> */}
                                        {/* <li> */}
                                            {/* <NavLink className="nav-link"
                                             activeClassName="link_active"
                                                   exact
                                                   to="https://www.goocampusaustralia.com/">
                                                    <img src={australia} alt="australia" height="20px" />AUS
                                               </NavLink>      */}
                                               {/* <a className="nav-link" target="_blank" href="https://www.goocampusaustralia.com/"><img src={australia} alt="australia" height="20px"  />AUS</a>
                                        </li> */}
                                    {/* <li><a href="https://www.goocampususa.com" target="_blank" rel="noopener noreferrer"><button className="abroad_btn no_shadow mr-2 py-1 px-2" style={{background: '#00d590', color: '#172a3a', border: '2px solid #172a3a'}}><img src={USAIcon} alt="UK" height="18" /><b> USA</b></button></a></li>
									<li><a href="https://www.goocampusaustralia.com" target="_blank" rel="noopener noreferrer"><button className="abroad_btn no_shadow mr-2 py-1 px-2" style={{background: '#00d590', color: '#172a3a', border: '2px solid #172a3a'}}><img src={AUSIcon} alt="UK" height="18" /><b> AUSTRALIA</b></button></a></li> */}
                                    {is_auth && auth_user  && parseInt(mobile_verified.mobile_verified)===1? (
                                     <li>
                                            <span className="nav-link show_profile">
                                                <i className="fa fa-user"></i>
                                                {auth_user.fname}
                                            </span>
                                        </li>
                                    ) : (
                                        <li className="pt-2">
                                            {/* <button className="abroad_btn no_shadow show_login">
                                                <i className="fa fa-sign-in"></i>
                                                Login
                                            </button> */}
                                            <NavLink
                                            className="abroad_btn no_shadow text-light"
                                            activeClassName="link_active"
                                            exact
                                            to="/login">
                                           <i className="fa fa-sign-in"></i> Login
                                        </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </nav>

                        <Route exact path="/" component={Home} />
                        <Route exact path="/login" component={Login}/>
                        <Route exact path="/about_us" component={AboutUs} />
                        <Route exact path="/pricing" component={Pricing} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/contact" component={Contact} />
                        <Route
                            exact
                            path="/state_filters"
                            component={StateFilters}
                        />
                        <Route
                            exact
                            path="/college_filters"
                            component={CollegeFilters}
                        />
                        <Route
                            exact
                            path="/college_details/:college"
                            component={CollegeDetails}
                        />
                        <Route
                            exact
                            path="/news_updates"
                            component={NewsUpdates}
                        />
                        <Route
                            exact
                            path="/news_details/:news_id"
                            component={NewsDetails}
                        />
                        <Route
                            exact
                            path="/calendar"
                            component={EventCalendar}
                        />
                        <Route
                            exact
                            path="/events/:type/:month/:year"
                            component={Events}
                        />
                        {/* <Route exact path="/cutoffs" component={Cutoffs} /> */}
                        <Route exact path="/predictor" component={Predictor} />
                        <Route exact path="/predictor_colleges" component={PredictedColleges}/>  
                        <Route
                            exact
                            path="/terms_conditions"
                            component={TermsConditions}
                        />
                        <Route
                            exact
                            path="/privacy_policy"
                            component={PrivacyPolicy}
                        />
                        <Route
                            exact
                            path="/college_compare"
                            component={CollegeCompare}
                        />
                        <Route exact path="/videos" component={Videos} />
                        <Route
                            exact
                            path="/forgot_password"
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path="/cutoff_settings"
                            component={CutoffSettings}
                        />
                        <Route exact path="/neet_results" component={Result} />

                        <Route
                            exact
                            path="/mbbs_course"
                            component={MBBSCourse}
                        />
                        <Route
                            exact
                            path="/md_ms_course"
                            component={MDMSCourse}
                        />
                        <Route
                            exact
                            path="/pg_diploma_course"
                            component={PGDiplomaCourse}
                        />
                        <Route exact path="/dnb_course" component={DNBCourse} />
                        <Route exact path="/bds_course" component={BDSCourse} />
                        <Route exact path="/mds_course" component={MDSCourse} />
                        <Route
                            exact
                            path="/bams_course"
                            component={BAMSCourse}
                        />
                        <Route
                            exact
                            path="/bhms_course"
                            component={BHMSCourse}
                        />
                        <Route
                            exact
                            path="/bums_course"
                            component={BUMSCourse}
                        />
                        <Route
                            exact
                            path="/bvsc_course"
                            component={BVScCourse}
                        />
                        <Route
                            exact
                            path="/mvsc_course"
                            component={MVScCourse}
                        />
                        
                   
                   
                     <Route 
                                exact
                                path="/get_otp"
                                component={Otp}/>
                                <Route 
                                exact
                                path="/register_otp"
                                component={RegisterOTP}/>
                            <Route
                                    exact
                                    path="/rank_predictor"
                                    component={RankPredictor}
                            />
                            <Route exact path="/predictor_form_payment" component={PredictorformPayment} />
                             <Route exact path="/download_payment" component={DownloadPayment} />       
                              <Route exact path="/predictor_benefits" component={PredictorBenefits}/>

                        {is_auth && auth_user ? (
                            <div>


                                <Route
                                    exact
                                    path="/checkout"
                                    component={Checkout}
                                />
                                <Route
                                    exact
                                    path="/change_stream_settings"
                                    component={ChangeStreamSettings}
                                />
                                <Route
                                    exact
                                    path="/edit_profile"
                                    component={EditProfile}
                                />
                                <Route
                                    exact
                                    path="/change_password"
                                    component={ChangePassword}
                                />
                                <Route
                                    exact
                                    path="/payment_success"
                                    component={PaymentSuccess}
                                />
                               
                                

                            </div>
                        ) : null}
                    </Router>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = state => {
    return {
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        college_states: state.state.college_states
    };
};

export default connect(mapStatesToProps)(Routes);
